// const id = '5fad5f8b69529866e82adb2b';
// const pageTitle = 'Վերապատրաստումներ';

import React, { useState, useEffect } from 'react';
import SinglePage from '../../SinglePage';
import { useHistory } from 'react-router-dom';
import * as API from '../../../helpers/api';
import config from '../../../config';
import Loading from '../../modules/loading';

export default function License() {
	const history = useHistory();
	const [isBusy, setBusy] = useState(true);
	const [id, setId] = useState('');
	const route = config.api.license;

	const [index, setIndex] = useState('');
	const [file, setfile] = useState(undefined);

	const modelSendToServer = {
		index,
	};

	useEffect(() => {
		(async () => {
			if (id !== 'add') {
				let currentItem = await API.get(route, {}, null, true);
        
				setId(currentItem[0]?._id);
				setIndex(currentItem[0]?.index);
				let files = currentItem?.[0]?.uploads;

				setfile(files && files);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy, history.location.pathname]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[]}
				disabledButtons={true}
				sliderComponent={[
					{
						name: 'images',
						destny: 'images',
						files: file,
						filesUploadLimit: 10,
						buttonAddText: 'Ավելացնել ֆայլեր',
						buttonSaveText: 'Պահպանել',
						buttonCloseText: 'Փակել',
						buttonRemoveText: 'Ջնջել',
						dropzoneText:
							'Բացակայում են ֆայլերը, սեղմեք այստեղ՝ պատկերներ ավելացնելու համար:',
						acceptedFiles: '.jpg, .jpeg, .png',
						responsiveImages: true,
					},
				]}
			/>
		);
}
