// const pageTitle = 'Կատեգորիա';
import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useLang } from "../../helpers/language";
import { useUser } from "../../helpers/userContext";
import { useParams } from "react-router-dom";

export default function CategoryPage() {
  const [index, setindex] = useState("");
  const [titleEn, settitleEn] = useState("");
  const [titleRu, settitleRu] = useState("");
  const [titleHy, settitleHy] = useState("");
  const [subtitleEn, setsubtitleEn] = useState("");
  const [subtitleRu, setsubtitleRu] = useState("");
  const [subtitleHy, setsubtitleHy] = useState("");
  const [alt, setAlt] = useState("");
  let id = useParams().id;

  const modelSendToServer = {
    index,
    titleEn,
    titleRu,
    titleHy,
    subtitleEn,
    subtitleRu,
    subtitleHy,
  };

  const [isBusy, setBusy] = useState(true);
  const route = config.api.category;
  const { user } = useUser();
  const { lang } = useLang();

  const [image, setImage] = useState("");

  useEffect(() => {
    (async () => {
      if (id !== "add") {
        let currentItem = await API.get(route, { _id: id }, null, true);
        setindex(currentItem[0]?.index);
        settitleEn(currentItem[0]?.titleEn);
        settitleRu(currentItem[0]?.titleRu);
        settitleHy(currentItem[0]?.titleHy);
        setsubtitleEn(currentItem[0]?.subtitleEn);
        setsubtitleRu(currentItem[0]?.subtitleRu);
        setsubtitleHy(currentItem[0]?.subtitleHy);

        let images = currentItem[0]?.uploads?.filter(
          (item) => item.destiny === "image"
        );
        setImage(images && images?.[0]);
        setAlt(images?.[0]?.alt);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setindex,
            type: "number",
            usageType: "number",
            label: "Index",
            placeholder: "Index",
          },
          {
            value: titleEn,
            setter: settitleEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.title[lang],
            placeholder: config.translate.title[lang],
            lang: "en",
          },
          {
            value: subtitleEn,
            setter: setsubtitleEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.subtitle[lang],
            placeholder: config.translate.subtitle[lang],
            lang: "en",
          },
          {
            value: titleRu,
            setter: settitleRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.title[lang],
            placeholder: config.translate.title[lang],
            lang: "ru",
          },
          {
            value: subtitleRu,
            setter: setsubtitleRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.subtitle[lang],
            placeholder: config.translate.subtitle[lang],
            lang: "ru",
          },
          {
            value: titleHy,
            setter: settitleHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.title[lang],
            placeholder: config.translate.title[lang],
            lang: "hy",
          },
          {
            value: subtitleHy,
            setter: setsubtitleHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.subtitle[lang],
            placeholder: config.translate.subtitle[lang],
            lang: "hy",
          },
        ]}
        filesComponent={[
          {
            destiny: "image",
            file: image,
            alt: alt,
            setAlt: setAlt,
            filesUploadLimit: 1,
            adminMode: user.role !== "editor",
            acceptedFiles: ".jpg, .jpeg, .png",
            thumbSizeWidth: 270,
            responsiveImages: true,
          },
        ]}
      />
    );
}
