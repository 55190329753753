import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import { useLang } from '../../helpers/language';

export default function Contact() {
	const [isBusy, setBusy] = useState(true);
	const route = config.api.contacts;
	const { lang } = useLang();
	const [id, setid] = useState('');

	const [descEn, setdescEn] = useState('');
	const [descRu, setdescRu] = useState('');
	const [descHy, setdescHy] = useState('');
	const [addressEn, setaddressEn] = useState('');
	const [addressRu, setaddressRu] = useState('');
	const [addressHy, setaddressHy] = useState('');
	const [tel, settel] = useState(undefined);
	const [email, setemail] = useState(undefined);

	const modelSendToServer = {
		descEn: descEn,
		descRu: descRu,
		descHy: descHy,
		addressEn: addressEn,
		addressRu: addressRu,
		addressHy: addressHy,
		tel: tel,
		email: email,
	};

	useEffect(() => {
		(async () => {
			if (id !== 'add') {
				let currentItem = await API.get(route);
				setid(currentItem[0]?._id);
				setdescEn(currentItem[0]?.descEn);
				setdescRu(currentItem[0]?.descRu);
				setdescHy(currentItem[0]?.descHy);
				setaddressEn(currentItem[0]?.addressEn);
				setaddressRu(currentItem[0]?.addressRu);
				setaddressHy(currentItem[0]?.addressHy);
				settel(currentItem[0]?.tel);
				setemail(currentItem[0]?.email);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: addressHy,
						setter: setaddressHy,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.address[lang],
						placeholder: config.translate.address[lang],
						lang: 'hy',
					},
					{
						value: addressRu,
						setter: setaddressRu,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.address[lang],
						placeholder: config.translate.address[lang],
						lang: 'ru',
					},
					{
						value: addressEn,
						setter: setaddressEn,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.address[lang],
						placeholder: config.translate.address[lang],
						lang: 'en',
					},
					{
						value: tel,
						setter: settel,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.tel[lang],
						placeholder: config.translate.tel[lang],
					},
					{
						value: email,
						setter: setemail,
						type: 'email',
						usageType: 'textfield',
						label: config.translate.email[lang],
						placeholder: config.translate.email[lang],
					},
					{
						value: descHy,
						setter: setdescHy,
						type: 'text',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'hy',
					},
					{
						value: descRu,
						setter: setdescRu,
						type: 'text',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'ru',
					},
					{
						value: descEn,
						setter: setdescEn,
						type: 'text',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'en',
					},
				]}
			/>
		);
}
