import React from 'react';
import { ListPage } from '../ListPage';
import config from '../../config';

export default function NewsList() {
	let customSort = 'nameHy';

	const columns = [
		{
			dataField: 'titleHy',
			text: 'Վերնագիր',
			filter: true,
			sort: true,
		},
		{
			dataField: 'titleRu',
			text: 'Заглавие',
			filter: true,
			sort: true,
		},
		{
			dataField: 'titleEn',
			text: 'Title',
			filter: true,
			sort: true,
		},

		{
			dataField: 'date',
			text: 'Ամսաթիվ',
			// filter: true,
			// sort: true,
			formatDate: true,
		},
	];

	return (
		<ListPage
			customSort={customSort}
			columns={columns}
			route={config.api.news}
			defaultSortedDataField={'index'}
			// rowStyle={rowStyle}
		/>
	);
}
