// const pageTitle = "Աշխատակից";
import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import * as API from "../../helpers/api";
import config from "../../config";
import { useParams } from "react-router-dom";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";

export default function Team() {
  let id = useParams().id;
  const [isBusy, setBusy] = useState(true);
  const route = config.api.team;
  const { user } = useUser();
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const [index, setindex] = useState("");
  const [nameEn, setnameEn] = useState("");
  const [nameRu, setnameRu] = useState("");
  const [nameHy, setnameHy] = useState("");
  const [descEn, setdescEn] = useState("");
  const [descRu, setdescRu] = useState("");
  const [descHy, setdescHy] = useState("");
  const [position_id, setposition_id] = useState("");
  // eslint-disable-next-line
  const [position, setposition] = useState("");
  const [positions, setpositions] = useState("");
  let modelSendToServer = {};

  if (position_id) {
    modelSendToServer = {
      index,
      nameEn,
      nameRu,
      nameHy,
      descEn,
      descRu,
      descHy,
      position_id,
    };
  } else {
    modelSendToServer = {
      index,
      nameEn,
      nameRu,
      nameHy,
      descEn,
      descRu,
      descHy,
    };
  }

  useEffect(() => {
    (async () => {
      if (id !== "add") {
        let currentItem = await API.get(route, { _id: id }, null, true);
        setindex(currentItem[0]?.index);
        setnameEn(currentItem[0]?.nameEn);
        setnameRu(currentItem[0]?.nameRu);
        setnameHy(currentItem[0]?.nameHy);
        setdescEn(currentItem[0]?.descEn);
        setdescRu(currentItem[0]?.descRu);
        setdescHy(currentItem[0]?.descHy);
        setposition_id(currentItem[0]?.position_id);

        let images = currentItem[0]?.uploads?.filter(
          (item) => item.destiny === "image"
        );
        setImage(images && images?.[0]);
        setAlt(images?.[0]?.alt);

        let rawPositions = await API.get(config.api.positions);
        setpositions(rawPositions);
        rawPositions?.forEach((pos) => {
          if (pos._id === currentItem[0]?.position_id) {
            setposition(pos);
          }
        });
      }

      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: index,
            setter: setindex,
            type: "number",
            usageType: "number",
            label: "Index",
            placeholder: "Index",
          },
          {
            value: nameEn,
            setter: setnameEn,
            type: "text",
            usageType: "textfield",
            label: "Name in english",
            placeholder: "Input name in latin",
            lang: "en",
          },
          {
            value: nameRu,
            setter: setnameRu,
            type: "text",
            usageType: "textfield",
            label: "Имя на русском",
            placeholder: "Ввести имя на кирилице",
            lang: "ru",
          },
          {
            value: nameHy,
            setter: setnameHy,
            type: "text",
            usageType: "textfield",
            label: "Անունը հայերեն",
            placeholder: "Մուտքագրել անունը հայատառ",
            lang: "hy",
          },
          {
            value: position_id,
            setter: setposition_id,
            optionListValue: positions,
            type: "optionlist",
            usageType: "optionlist",
            label: "Հաստիք",
            placeholder: "Հաստիք",
            lang: "hy",
          },
          {
            value: position_id,
            setter: setposition_id,
            optionListValue: positions,
            type: "optionlist",
            usageType: "optionlist",
            label: "Должность",
            placeholder: "Должность",
            lang: "ru",
          },
          {
            value: position_id,
            setter: setposition_id,
            optionListValue: positions,
            type: "optionlist",
            usageType: "optionlist",
            label: "Position",
            placeholder: "Position",
            lang: "en",
          },
          {
            value: descHy,
            setter: setdescHy,
            type: "text",
            usageType: "textarea",
            label: "Նկարագրություն",
            placeholder: "Նկարագրություն",
            fullWidth: true,
            lang: "hy",
          },
          {
            value: descRu,
            setter: setdescRu,
            type: "text",
            usageType: "textarea",
            label: "Описание",
            placeholder: "Описание",
            fullWidth: true,
            lang: "ru",
          },
          {
            value: descEn,
            setter: setdescEn,
            type: "text",
            usageType: "textarea",
            label: "Description",
            placeholder: "Description",
            fullWidth: true,
            lang: "en",
          },
        ]}
        filesComponent={[
          {
            destiny: "image",
            file: image,
            alt: alt,
            setAlt: setAlt,
            filesUploadLimit: 1,
            adminMode: user.role !== "editor",
            acceptedFiles: ".jpg, .jpeg, .png",
            thumbSizeWidth: 270,
            responsiveImages: true,
          },
        ]}
      />
    );
}
