import React from "react";

export default function Loading({ progress }) {
  return (
    <div className="overlay">
      <div className="container">
        <div className="loader">
          {progress === 100 ? (
            <div className="loader-text">Compressing</div>
          ) : (
            <div className="loader-text">{progress} %</div>
          )}
        </div>
      </div>
    </div>
  );
}
