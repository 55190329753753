import React from "react";
import { Link, useLocation } from "react-router-dom";
import { navigationPrimary, navigationSecondary } from "./listItems";
import { useAuth } from "../../helpers/auth";
import { useUser } from "../../helpers/userContext";

export default function Sidebar() {
  const { logout } = useAuth();
  const location = useLocation();
  const { user } = useUser();

  const toggleDrawer = (e) => {
    let elem = e.currentTarget.parentNode;
    if (elem.classList.contains("menu-open"))
      elem.classList.remove("menu-open");
    else elem.classList.add("menu-open");
  };
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  };

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <span className="brand-link">
        <img
          src="/logo.png"
          alt="Website Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: 0.8 }}
        />
        <span className="brand-text font-weight-light">
          Fanarjyanclinic.com
        </span>
      </span>

      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="info">
            <span className="d-block">{user?.name}</span>
          </div>
          <div className="info">
            <span onClick={logout} className="d-block">
              <i className="fas fa-sign-out-alt"></i>
            </span>
          </div>
        </div>

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column nav-child-indent"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-header">ՀԻմնական</li>

            {navigationPrimary.map((item, index) => {
              return (
                <li
                  className={`nav-item ${item.collapse && "has-treeview"}`}
                  key={index}
                >
                  {item.collapse ? (
                    <>
                      <span className="nav-link" onClick={toggleDrawer}>
                        <i className={`nav-icon ${item.icon}`}></i>
                        <p>
                          {item.name.charAt(0).toUpperCase() +
                            item.name.slice(1)}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </span>
                      <ul className="nav nav-treeview">
                        {item.collapse.map((innerItem, innerIndex) => {
                          return (
                            <li className="nav-item" key={innerIndex}>
                              <Link
                                className={
                                  activeRoute(`/${innerItem.route}/`)
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                to={`/${innerItem.route}/`}
                              >
                                <i className={`nav-icon ${innerItem.icon}`}></i>
                                <p>
                                  {innerItem.name.charAt(0).toUpperCase() +
                                    innerItem.name.slice(1)}
                                </p>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <Link
                      to={`/${item.route}/`}
                      className={
                        activeRoute(item.route) ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className={`nav-icon ${item.icon}`}></i>
                      <p>
                        {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                      </p>
                    </Link>
                  )}
                </li>
              );
            })}

            <li className="nav-header">Հավելյալ</li>
            {navigationSecondary.map((item, index) => {
              return (
                <li
                  className={`nav-item ${item.collapse && "has-treeview"}`}
                  key={index}
                >
                  {item.collapse ? (
                    <>
                      <span className="nav-link" onClick={toggleDrawer}>
                        <i className={`nav-icon ${item.icon}`}></i>
                        <p>
                          {item.name.charAt(0).toUpperCase() +
                            item.name.slice(1)}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </span>
                      <ul className="nav nav-treeview">
                        {item.collapse.map((innerItem, innerIndex) => {
                          return (
                            <li className="nav-item" key={innerIndex}>
                              <a className="nav-link" href={innerItem.route}>
                                <i className={`nav-icon ${innerItem.icon}`}></i>
                                <p>
                                  {innerItem.name.charAt(0).toUpperCase() +
                                    innerItem.name.slice(1)}
                                </p>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <Link
                      to={`/${item.route}/`}
                      className={
                        activeRoute(item.route) ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className={`nav-icon ${item.icon}`}></i>
                      <p>
                        {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                      </p>
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </aside>
  );
}
