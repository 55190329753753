import React from 'react';
import { ListPage } from '../ListPage';
import config from '../../config';

export default function Videos() {
	let customSort = 'nameHy';

	const columns = [
		{
			dataField: 'index',
			text: 'Index',
			filter: true,
			sort: true,
		},
		{
			dataField: 'nameHy',
			text: 'Անուն',
			filter: true,
			sort: true,
		},
		{
			dataField: 'nameRu',
			text: 'Имя',
			filter: true,
			sort: true,
		},
		{
			dataField: 'nameEn',
			text: 'Name',
			filter: true,
			sort: true,
		},
		{
			dataField: 'video',
			text: 'Վիդեո',
			formatVideo: true,
		},
		{
			dataField: 'image',
			text: 'Նկար',
			format: true,
		},
	];

	return (
		<ListPage
			customSort={customSort}
			columns={columns}
			route={config.api.video}
			defaultSortedDataField={'index'}
			// rowStyle={rowStyle}
		/>
	);
}
