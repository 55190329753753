import React from 'react';
import { ListPage } from '../ListPage';
import config from '../../config';

export default function Categories() {
	let customSort = 'index';

	const columns = [
		{
			dataField: 'index',
			text: 'Index',
			filter: true,
			sort: true,
		},
		{
			dataField: 'titleHy',
			text: 'Վերնագիր',
			filter: true,
			sort: true,
		},
		{
			dataField: 'titleRu',
			text: 'Заголовок',
			filter: true,
			sort: true,
		},
		{
			dataField: 'titleEn',
			text: 'Title',
			filter: true,
			sort: true,
		},
	];

	return (
		<ListPage
			customSort={customSort}
			columns={columns}
			route={config.api.category}
			defaultSortedDataField={'index'}
			// rowStyle={rowStyle}
		/>
	);
}
