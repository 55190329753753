//   const pageTitle = 'Նորություն';
import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import getUploads from '../../helpers/getUploads';
import { useLang } from '../../helpers/language';
import { useUser } from '../../helpers/userContext';
import { useParams } from 'react-router-dom';

export default function News() {
  let id = useParams().id;
  const [isBusy, setBusy] = useState(true);
  const route = config.api.news;
  const { user } = useUser();
  const { lang } = useLang();
  const [image, setImage] = useState('');
  const [alt, setAlt] = useState('');

  const [titleEn, setTitleEn] = useState('');
  const [titleRu, setTitleRu] = useState('');
  const [titleHy, setTitleHy] = useState('');
  const [descEn, setDescEn] = useState('');
  const [descRu, setDescRu] = useState('');
  const [descHy, setDescHy] = useState('');
  const [date, setdate] = useState('');

  const modelSendToServer = {
    titleEn,
    titleRu,
    titleHy,
    descEn,
    descRu,
    descHy,
    date,
  };

  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        let currentItem = await API.get(route, { _id: id });
        setTitleEn(currentItem[0]?.titleEn);
        setTitleRu(currentItem[0]?.titleRu);
        setTitleHy(currentItem[0]?.titleHy);
        setDescEn(currentItem[0]?.descEn);
        setDescRu(currentItem[0]?.descRu);
        setDescHy(currentItem[0]?.descHy);

        let images = await getUploads(id, 'image');
        setImage(images && images?.[0]);
        setAlt(images?.data[0]?.alt);

        currentItem[0]?.date && setdate(new Date(currentItem[0]?.date));
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: titleEn,
            setter: setTitleEn,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.title[lang],
            placeholder: config.translate.title[lang],
            lang: 'en',
          },
          {
            value: titleRu,
            setter: setTitleRu,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.title[lang],
            placeholder: config.translate.title[lang],
            lang: 'ru',
          },
          {
            value: titleHy,
            setter: setTitleHy,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.title[lang],
            placeholder: config.translate.title[lang],
            lang: 'hy',
          },
          {
            value: date,
            setter: setdate,
            type: 'date',
            usageType: 'datePicker',
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
          },
          {
            value: descHy,
            setter: setDescHy,
            type: 'text',
            usageType: 'textarea',
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            lang: 'hy',
            fullWidth: true,
          },
          {
            value: descRu,
            setter: setDescRu,
            type: 'text',
            usageType: 'textarea',
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            lang: 'ru',
            fullWidth: true,
          },
          {
            value: descEn,
            setter: setDescEn,
            type: 'text',
            usageType: 'textarea',
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            lang: 'en',
            fullWidth: true,
          },
        ]}
        filesComponent={[
          {
            destiny: 'image',
            file: image,
            filesUploadLimit: 1,
            adminMode: user.role !== 'editor',
            acceptedFiles: '.jpg, .jpeg, .png',
            thumbSizeWidth: 270,
            responsiveImages: true,
          },
        ]}
      />
    );
}
