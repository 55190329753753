//   const pageTitle = 'Պացիենտ';
import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import * as API from '../../helpers/api';
import config from '../../config';
import { useParams } from 'react-router-dom';
import Loading from '../modules/loading';
import { useLang } from '../../helpers/language';
import { useUser } from '../../helpers/userContext';

export default function Videos() {
	let id = useParams().id;
	const [isBusy, setBusy] = useState(true);
	const route = config.api.video;
	const { user } = useUser();
	const { lang } = useLang();

	const [index, setindex] = useState(0);
	const [nameEn, setnameEn] = useState('');
	const [nameRu, setnameRu] = useState('');
	const [nameHy, setnameHy] = useState('');
	const [descEn, setdescEn] = useState('');
	const [descRu, setdescRu] = useState('');
	const [descHy, setdescHy] = useState('');
	const [video, setVideo] = useState(undefined);
	const [image, setImage] = useState(undefined);
	const [altImage, setAltImage] = useState('');
	const [altVideo, setAltVideo] = useState('');

	const modelSendToServer = {
		index,
		nameEn,
		nameRu,
		nameHy,
		descEn,
		descRu,
		descHy,
	};

	let fileTypeImage = new RegExp(`^image.*$`);
	let fileTypeVideo = new RegExp(`^video.*$`);

	useEffect(() => {
		(async () => {
			if (id !== 'add') {
				let currentItem = await API.get(
					config.api.video,
					{ _id: id },
					null,
					true
				);
				setindex(currentItem?.[0]?.index);
				setnameEn(currentItem?.[0]?.nameEn);
				setnameRu(currentItem?.[0]?.nameRu);
				setnameHy(currentItem?.[0]?.nameHy);
				setdescEn(currentItem?.[0]?.descEn);
				setdescRu(currentItem?.[0]?.descRu);
				setdescHy(currentItem?.[0]?.descHy);

				let image, video;
				image = currentItem?.[0]?.uploads?.filter(
					({ type }) =>
						type.match(
							fileTypeImage
						)
				)[currentItem?.[0]?.uploads?.filter(
					({ type }) =>
						type.match(
							fileTypeImage
						)
				).length - 1];
				video = currentItem?.[0]?.uploads?.filter(
					({ type }) =>
						type.match(
							fileTypeVideo
						)
				)[currentItem?.[0]?.uploads?.filter(
					({ type }) =>
						type.match(
							fileTypeVideo
						)
				).length - 1];

				setImage(image && image);
				setAltImage(image?.alt);
				setVideo(video && video);
				setAltVideo(video?.alt);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setindex,
						type: 'number',
						usageType: 'number',
						label: 'Index',
						placeholder: 'Index',
					},
					{
						value: nameHy,
						setter: setnameHy,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: 'hy',
						fullWidth: true,
					},
					{
						value: nameRu,
						setter: setnameRu,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: 'ru',
						fullWidth: true,
					},
					{
						value: nameEn,
						setter: setnameEn,
						type: 'text',
						usageType: 'textfield',
						label: config.translate.name[lang],
						placeholder: config.translate.name[lang],
						lang: 'en',
						fullWidth: true,
					},
					{
						value: descHy,
						setter: setdescHy,
						type: 'text',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'hy',
						fullWidth: true,
					},
					{
						value: descRu,
						setter: setdescRu,
						type: 'text',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'ru',
						fullWidth: true,
					},
					{
						value: descEn,
						setter: setdescEn,
						type: 'text',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'en',
						fullWidth: true,
					},
				]}
				filesComponent={[
					{
						destiny: 'image',
						file: image,
						filesUploadLimit: 1,
						alt: altImage,
						setAlt: setAltImage,
						adminMode: user.role !== 'editor',
						acceptedFiles: '.jpg, .jpeg, .png',
						thumbSizeWidth: 270,
						responsiveImages: true,
					},
				]}
				videoComponent={[
					{
						name: 'video',
						destiny: 'video',
						file: video,
						filesUploadLimit: 1,
						alt: altVideo,
						setAlt: setAltVideo,
						adminMode: user.role !== 'editor',
						acceptedFiles: ['video/*'],
						buttonAddText: 'Ավելացնել',
						buttonOpenText: 'Բացել',
						buttonRemoveText: 'Ջնջել',
						dropzoneText:
							'Բացակայում է ֆայլը, սեղմեք այստեղ՝ Տեսահոլովակ ավելացնելու համար:',
					},
				]}
			/>
		);
}
