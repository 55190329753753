import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import * as API from "../helpers/api";
import config from "../config";
import { useUser } from "../helpers/userContext";
import parse from "html-react-parser";
import moment from "moment";
import { useLang } from "../helpers/language";

export function ListPage(props) {
  let history = useHistory();
  const [isBusy, setBusy] = useState(true);
  const [columns, setColumns] = useState(props.columns);
  const disableAddButton = !!props.disableAddButton;
  const hideAddButton = !!props.hideAddButton;
  // eslint-disable-next-line
  const [images, setImages] = useState("");
  const [clients, setClients] = useState("");
  const { user } = useUser();
  const { lang } = useLang();

  const [apiData, setApiData] = useState([]);
  const location = useLocation();

  const interactivePageTitle = () => {
    let result = "";
    Object.values(config.menu.primary).forEach((item) => {
      if (item.route) {
        if (location.pathname.indexOf(item.route) > 0) {
          result = item.name[lang];
        }
      } else {
        item.collapse.forEach((innerItem) => {
          if (location.pathname.indexOf(innerItem.route) > 0) {
            result = innerItem.name[lang];
          }
        });
      }
    });
    if (!result) {
      Object.values(config.menu.secondary).forEach((item) => {
        if (item.route) {
          if (location.pathname.indexOf(item.route) > 0) {
            result = item.name[lang];
          }
        } else {
          item.collapse.forEach((innerItem) => {
            if (location.pathname.indexOf(innerItem.route) > 0) {
              result = innerItem.name[lang];
            }
          });
        }
      });
    }
    return result;
  };

  const defaultSorted = [
    {
      dataField: props.defaultSortedDataField
        ? props.defaultSortedDataField
        : "name.ru",
      order: "asc",
    },
  ];

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "Первый",
    prePageText: "Назад",
    nextPageText: "Next",
    lastPageText: "Следующий",
    nextPageTitle: "Первая страница",
    prePageTitle: "Предварительная страница",
    firstPageTitle: "Следущая страница",
    lastPageTitle: "Предыдущая страница",
    showTotal: true,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: apiData?.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const addNew = async () => {
    let result;
    try {
      result = await API.post(props.route, { temprorary: true });
      history.push(`/${props.route}/${result._id}?temprorary=true`);
    } catch (error) {
      console.log(error);
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/${props.route}/${row._id}`);
    },
  };

  const roleFormatter = (cell, row, rowIndex, formatExtraData) => {
    let result;
    if (cell === "admin") result = "Администратор";
    else if (cell === "manager") result = "Руководитель";
    else if (cell === "editor") result = "Сотрудник";
    else result = "";
    return result;
  };
  const imageFormatter = (cell, row, rowIndex, formatExtraData) => {
    let result = <i className="fas fa-minus"></i>;
    images?.forEach((image) => {
      if (image.obj_id === row._id) result = <i className="far fa-image"></i>;
    });
    return result;
  };
  const clientIdFormatter = (cell, row, rowIndex, formatExtraData) => {
    let result = "Չգրանցված հաճախորդ";
    clients?.forEach((item) => {
      if (item._id === row.client_id)
        result = (
          <span>
            <i className="far fa-user" style={{ marginRight: 10 }}></i>
            {item.username && item.username}
          </span>
        );
    });
    return result;
  };
  const addressFormatter = (cell, row, rowIndex, formatExtraData) => {
    let result = "Հասցեն նշված չի";
    if (row.delivery.method === "true") {
      return row.delivery.address;
    }
    return result;
  };
  const cityFormatter = (cell, row, rowIndex, formatExtraData) => {
    let result = "Խանութից";
    if (row.delivery.method === "true") {
      return config.ARMENIAN_CITIES.map((item) => {
        if (item._id === row.delivery.city) {
          return item.name[lang];
        }
      });
    }
    return result;
  };
  const descFormatter = (cell, row, rowIndex, formatExtraData) => {
    let result;
    result =
      row.desc && row.desc.length > 20
        ? parse(row.desc.slice(0, 20) + "...")
        : parse(row.desc);
    return result;
  };
  const statusFormatter = (cell, row, rowIndex, formatExtraData) => {
    let result = "";
    let statuses = config.statuses;
    if (statuses[0]?._id === row.status) {
      result += `<img class="icon-progress icon-progress-empty" src="/assets/progress-empty-svgrepo-com.svg" alt="live"/> `;
    } else if (statuses[1]?._id === row.status) {
      result += `<img class="icon-progress icon-progress-one" src="/assets/progress-one-svgrepo-com.svg" alt="live"/> `;
    } else if (statuses[2]?._id === row.status) {
      result += `<img class="icon-progress icon-progress-two" src="/assets/progress-two-svgrepo-com.svg" alt="live"/> `;
    } else if (statuses[3]?._id === row.status) {
      result += `<img class="icon-progress icon-progress-full" src="/assets/progress-full-svgrepo-com.svg" alt="live"/> `;
    }
    statuses.constructor.name !== "String" &&
      statuses?.forEach((item) => {
        if (item._id === row.status) {
          result += item.name.hy
            ? item.name.hy
            : item.name.ru
            ? item.name.ru
            : item.name.en
            ? item.name.en
            : item.name.en;
        }
      });
    return parse(result);
  };
  const intervalFormatter = (cell, row, rowIndex, formatExtraData) => {
    let result = "";
    if (row.closedDate) {
      result =
        moment(moment(row.closedDate) - moment(row.openedDate)).format("m") +
        " мин.";
    } else {
      result = "...";
    }
    return parse(result);
  };
  const dateFormatter = (cell, row, rowIndex, formatExtraData) => {
    let result;
    // let yearsOld = moment().diff(cell, 'years');
    let date = moment(cell).format("DD/MM/YYYY");
    result = `${date}`;
    let icon = `<img className="birthday-icon" src="/assets/box-full-svgrepo-com.svg" alt=""birthday/>`;

    var today = new Date();
    var nowMonth = today.getMonth();
    var nowDay = today.getDate();

    let birth = new Date(cell);
    var birthMonth = birth.getMonth();
    var birthDay = birth.getDate();

    var compBirth = birthMonth.toString() + birthDay.toString();
    var compToday = nowMonth.toString() + nowDay.toString();

    if (user.role !== "editor") {
      if (compBirth === compToday) {
        // console.log('Today is your birthday!');
        result += icon;
      } else if (compBirth > compToday) {
        // console.log('Your birthday is comming!');
      } else {
        // console.log('Happy b-lated day!');
      }
    }

    return parse(result);
  };

  columns.forEach(async (column) => {
    if (column.filter === true) column.filter = textFilter();
    if (column.formatImage === true) column.formatter = imageFormatter;
    if (column.formatDate === true) column.formatter = dateFormatter;
    if (column.formatStatus === true) column.formatter = statusFormatter;
    if (column.formatClientId === true) column.formatter = clientIdFormatter;
    if (column.formatAddress === true) column.formatter = addressFormatter;
    if (column.formatCity === true) column.formatter = cityFormatter;
    if (column.formatDesc === true) column.formatter = descFormatter;
    if (column.formatRole === true) column.formatter = roleFormatter;
    if (column.formatIntervl === true) column.formatter = intervalFormatter;
  });

  useEffect(() => {
    (async () => {
      let rawData;
      if (user.role !== "editor") {
        rawData = await API.get(
          props.route,
          props.query
            ? { temprorary: { $ne: true }, ...props.query }
            : { temprorary: { $ne: true } }
        );
      } else {
        rawData = await API.get(
          props.route,
          props.query
            ? {
                createdByUser: user._id,
                temprorary: { $ne: true },
                ...props.query,
              }
            : { temprorary: { $ne: true } }
        );
      }
      setApiData(rawData);
      setColumns(props.columns);

      setBusy(false);
    })();

    // eslint-disable-next-line
  }, [isBusy]);

  if (!isBusy) {
    return (
      <>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12" align="center">
                <h1>{interactivePageTitle()}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {!hideAddButton && (
                      <button
                        className="btn btn-primary mb-3"
                        onClick={addNew}
                        disabled={disableAddButton}
                      >
                        <i className="fas fa-plus-square"></i>
                        <span
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          {config.translate.addNew[lang]}
                        </span>
                      </button>
                    )}
                    <BootstrapTable
                      bootstrap4
                      hover
                      keyField="_id"
                      data={apiData}
                      columns={columns}
                      defaultSorted={defaultSorted}
                      filter={filterFactory()}
                      pagination={paginationFactory(options)}
                      rowEvents={rowEvents}
                      rowStyle={props.rowStyle}
                      headerClasses="table-header-class"
                      rowClasses={props.rowClasses}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  } else return <h1>Loading</h1>;
}
