export const navigationPrimary = [
  {
    name: "մեր մասին",
    icon: "fas fa-address-card",
    collapse: [
      {
        name: "ողջույնի խոսք",
        route: "mission",
        icon: "fas fa-route",
      },
      {
        name: "հիմնական",
        route: "history",
        icon: "fas fa-history",
      },
      {
        name: "վերապատրաստումներ",
        route: "license",
        icon: "fas fa-file",
      },
    ],
  },
  { name: "կատեգորիաներ", route: "category", icon: "fas fa-clone" },
  {
    name: "ծառայություններ",
    route: "services",
    icon: "fas fa-concierge-bell",
  },
  {
    name: "մեր բժիշկները",
    route: "team",
    icon: "fas fa-users",
  },
  { name: "հոդվածներ", route: "patients", icon: "fas fa-user-tie" },
  { name: "վիդեոներ", route: "video", icon: "fas fa-video" },
  {
    name: "ֆոտոդարան",
    route: "gallery",
    icon: "fas fa-icons",
  },
];
export const navigationSecondary = [
  { name: "հաստիքներ	", route: "positions", icon: "fas fa-clone" },
  { name: "մեդիա", route: "media", icon: "fas fa-icons" },
  {
    name: "Ծառայությունների մատուցման պայմաններ",
    route: "terms",
    icon: "fas fa-icons",
  },
  {
    name: "Գաղտնիության քաղաքականություն",
    route: "privacy",
    icon: "fas fa-icons",
  },
];
