import React, { useState, useEffect } from 'react';
import config from '../../config';
import Dropzone, { useDropzone } from 'react-dropzone';
import * as API from '../../helpers/api';
import { useHistory } from 'react-router-dom';
import Loading from './loading';

export function VideoComponent({
	id,
	file,
	setAlt,
	route,
	modelSendToServer,
	lang,
	destiny,
	thumbSizeWidth,
	thumbSizeHeight,
	responsiveImages,
	setBusy,
	dropzoneText,
	buttonOpenText,
	buttonRemoveText,
	slider,
	acceptedFiles,
	fileRejections,
	getRootProps,
	getInputProps,
	alt,
	filesUploadLimit,
}) {
	var disabled = false;
	var fileId = '';
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);

	const handleChange = (e) => {
		setAlt(e.target && e.target.value);
	};

	if (file === undefined || file === '') {
		disabled = true;
	} else if (file) {
		fileId = file._id;
	}

	async function removeFile(e) {
		try {
			await API.remove(config.api.upload, e.currentTarget.id);
			setLoading(true);
			// setLoading(false);
			setBusy(true);
		} catch (err) {
			console.log(err);
		}
	}

	async function handleSave(files, lang, destiny, alt, setProgress) {
		setLoading(true);
		if (id === 'add') {
			const rowData = await API.post(route, modelSendToServer);
			let id = rowData[0]._id;
			await history.push(`${id}`);
			let result = await API.upload(
				id,
				files,
				lang,
				destiny,
				thumbSizeWidth,
				thumbSizeHeight,
				responsiveImages,
				alt,
				setProgress
			);
			if (result) {
				setLoading(false);
			} else {
				console.log('result is null');
			}
		} else {
			try {
				await API.update(route, id, modelSendToServer);
				try {
					let result = await API.upload(
						id,
						files,
						lang,
						destiny,
						thumbSizeWidth,
						thumbSizeHeight,
						responsiveImages,
						alt,
						setProgress
					);
					if (result) {
						setLoading(false);
					} else {
						console.log('result is null');
					}
				} catch (err) {
					console.log(err);
				}
			} catch (error) {
				console.log(error);
			}
		}
		setBusy(true);
	}

	const openInNewTab = (e) => {
		let win = window.open(`${config.api.API_URL}/${file.path}`, '_blank');
		win.focus();
	};

	if (loading) return <Loading progress={progress} />;
	else
		return (
			<>
				<div className='col-md-12'>
					<div className='card card-secondary'>
						<div className='card-header'>
							<h3 className='card-title'>Ֆայլ</h3>
							<input
								value={alt || ''}
								onChange={handleChange}
								type='text'
								disabled={file ? true : false}
								className='form-control'
								placeholder='Alternative text for SEO optimization: Write text before upload file'
							/>
						</div>
						<div className='card-body'>
							{!disabled ? (
								file.type === 'application/pdf' ? (
									<embed
										type='application/pdf'
										width='100%'
										src={`${config.api.API_URL}/${file.path}`}
									/>
								) : (
									// <img
									// 	src={`${config.api.API_URL}/${props.file.path}`}
									// 	alt={props.file.alt}
									// />
									<video
										loop
										muted
										autoplay
										playsInline
										preload='metadata'
										controls
										width='100%'
										className='video'
									>
										<source
											src={`${config.api.API_URL}/${file.path}`}
											type='video/mp4'
										/>
									</video>
								)
							) : (
								<Dropzone
									onDrop={(acceptedFiles) =>
										handleSave(
											acceptedFiles,
											lang,
											destiny,
											alt,
											setProgress
										)
									}
									accept={acceptedFiles}
								>
									{({ getRootProps, getInputProps }) => (
										<section className='dropzone'>
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												<p>{dropzoneText}</p>
											</div>
										</section>
									)}
								</Dropzone>
								// <Accept
								// 	acceptedFiles={acceptedFiles}
								// 	fileRejections={fileRejections}
								// 	getRootProps={getRootProps}
								// 	getInputProps={getInputProps}
								// 	handleSave={handleSave}
								// />
							)}
						</div>
						<div className='card-footer'>
							<div className='btn-group'>
								<button
									onClick={openInNewTab}
									disabled={slider || disabled}
									display={slider ? 'none' : 'inline'}
									type='button'
									className='btn btn-default'
								>
									<i className='fas fa-share'></i>
									<span style={{ marginLeft: 10 }}>
										{buttonOpenText}
									</span>
								</button>
								<button
									onClick={removeFile}
									disabled={disabled}
									type='button'
									id={fileId}
									className='btn btn-danger'
								>
									<i className='fas fa-trash'></i>
									<span style={{ marginLeft: 10 }}>
										{buttonRemoveText}
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
}
