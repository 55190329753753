import React, { useState, useEffect, Suspense } from "react";
// import './adminlte.css';
import "./scss/AdminLTE.scss";

// Helpers
import history from "./helpers/history";
import { cookies, setCookies } from "./helpers/cookies";
import { isLoggedIn } from "./helpers/auth";
import { Language } from "./helpers/language";
import { UserContext } from "./helpers/userContext";
import { ConnectedUserContext } from "./helpers/connectedUserContext";
import API from "./helpers/api";
import config from "./config";
import Loading from "./components/modules/loading.jsx";

// Components
import Patients from "./components/patients/patients";
import Patient from "./components/patients/patient";
import Videos from "./components/videos/videos";
import Video from "./components/videos/video";
import CategoryList from "./components/category/categoryList";
import CategoryPage from "./components/category/categoryPage";
import Positions from "./components/positions/positions";
import Position from "./components/positions/position";
import Team from "./components/team/team";
import TeamList from "./components/team/team-list";
import Services from "./components/services/services";
import Service from "./components/services/service";
import Contacts from "./components/contacts/contacts";
import NewsList from "./components/news/news-list";
import News from "./components/news/news";
import Media from "./components/media/media";
import Gallery from "./components/gallery/gallery";
import License from "./components/about-us/license/license";
import Mission from "./components/about-us/mission/mission";
import History from "./components/about-us/history/history";
import Terms from "./components/terms/terms";
import Privacy from "./components/privacy/privacy";
// global components
import Login from "./components/login";
import Home from "./components/home";
import Main from "./components/main/main";
import Page404 from "./components/Page404";

//Routing
import { Router, Route, Switch } from "react-router-dom";

function RouterComponent({ user }) {
  // window.scrollTo(0, 0);

  if (user) {
    return (
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/mission" component={Mission} />
          <Route exact path="/history" component={History} />
          <Route exact path="/license" component={License} />
          <Route exact path="/team" component={TeamList} />
          <Route exact path="/team/:id" component={Team} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/services/:id" component={Service} />
          <Route exact path="/category" component={CategoryList} />
          <Route exact path="/category/:id" component={CategoryPage} />
          <Route exact path="/contacts" component={Contacts} />
          <Route exact path="/patients" component={Patients} />
          <Route exact path="/patients/:id" component={Patient} />
          <Route exact path="/video" component={Videos} />
          <Route exact path="/video/:id" component={Video} />
          <Route exact path="/positions" component={Positions} />
          <Route exact path="/positions/:id" component={Position} />
          <Route exact path="/news" component={NewsList} />
          <Route exact path="/news/:id" component={News} />
          <Route exact path="/media" component={Media} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
        </Switch>
      </Suspense>
    );
  } else {
    return (
      <Suspense>
        <Route path="*" component={Page404} />
      </Suspense>
    );
  }
}

function App() {
  let [loggedIn, setLoggedIn] = useState(Boolean);
  let [lang, setLang] = useState("hy");
  let [user, setUser] = useState(Boolean);
  // eslint-disable-next-line
  async function logout() {
    await setCookies("");
    await setLoggedIn(false);
  }

  function login(username, password, toggleAlert) {
    if (username && password) {
      API.post(config.authentification.login, {
        username,
        password,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(res.data.token);
            setLoggedIn(true);
            setUser(res.data.user);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoggedIn(false);
          toggleAlert();
        });
    } else {
      setLoggedIn(false);
      console.log("There is no user with provided username & password");
      toggleAlert();
    }
  }

  function checkToken() {
    let token = cookies.get(config.authentification.tokenAddress);
    if (token) {
      API.post(config.authentification.verify, {
        token,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(token);
            setUser(res.data.user);
            setLoggedIn(true);
          } else {
            setLoggedIn(false);
          }
        })
        .catch((res) => {
          console.error(`Please don't edit cookies manually`);
          cookies.remove("authorization");
        });
    } else {
      setLoggedIn(false);
      console.log(
        "You must provide token for calling login -> veryfyToken functions, Or mongodb database is not running"
      );
    }
  }

  useEffect(() => {
    (async () => {
      await checkToken();
      await Notification.requestPermission();
    })();
    // eslint-disable-next-line
  }, []);

  if (loggedIn && user) {
    return (
      <Language.Provider value={{ lang, setLang }}>
        <isLoggedIn.Provider value={{ login, logout, loggedIn }}>
          <UserContext.Provider value={{ user }}>
            <Router history={history}>
              <Main component={RouterComponent} user={user} />
            </Router>
          </UserContext.Provider>
        </isLoggedIn.Provider>
      </Language.Provider>
    );
  } else {
    return (
      <Language.Provider value={{ lang, setLang }}>
        <isLoggedIn.Provider value={{ login, logout, loggedIn }}>
          <Router history={history}>
            <Route component={Login} />
          </Router>
        </isLoggedIn.Provider>
      </Language.Provider>
    );
  }
}

export default App;
