// const id = '5fad5f9c69529866e82adb2c';
// const pageTitle = 'Ողջույնի խոսք';

import React, { useState, useEffect } from 'react';
import SinglePage from '../../SinglePage';
import * as API from '../../../helpers/api';
import config from '../../../config';
import Loading from '../../modules/loading';
import { useLang } from '../../../helpers/language';
import { useUser } from '../../../helpers/userContext';

export default function Mission() {
	const [isBusy, setBusy] = useState(true);
	const route = config.api.mission;
	const { user } = useUser();
	const { lang } = useLang();

	const [descEn, setDescEn] = useState('');
	const [descRu, setDescRu] = useState('');
	const [descHy, setDescHy] = useState('');

	const [image, setImage] = useState('');
	const [alt, setAlt] = useState('');
	const [id, setId] = useState('');

	const modelSendToServer = {
		descEn,
		descRu,
		descHy,
	};

	useEffect(() => {
		(async () => {
			let currentItem = await API.get(route, {}, null, true);
			setId(currentItem[0]?._id);
			setDescEn(currentItem[0]?.descEn);
			setDescRu(currentItem[0]?.descRu);
			setDescHy(currentItem[0]?.descHy);

			let image;
			currentItem?.[0]?.uploads?.forEach((item) => {
				if (item.destiny === 'image') {
					image = item;
				}
			});

			setImage(image && image);
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: descHy,
						setter: setDescHy,
						type: 'text',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'hy',
						fullWidth: true,
					},
					{
						value: descRu,
						setter: setDescRu,
						type: 'text',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'ru',
						fullWidth: true,
					},
					{
						value: descEn,
						setter: setDescEn,
						type: 'text',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'en',
						fullWidth: true,
					},
				]}
				filesComponent={[
					{
						destiny: 'image',
						file: image,
						alt: alt,
						setAlt: setAlt,
						filesUploadLimit: 1,
						adminMode: user.role !== 'editor',
						acceptedFiles: '.jpg, .jpeg, .png',
						thumbSizeWidth: 270,
						responsiveImages: true,
					},
				]}
			/>
		);
}
