//   const pageTitle = 'Նորություն';
import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import { useLang } from '../../helpers/language';

export default function Privacy() {
	const [isBusy, setBusy] = useState(true);
	const route = config.api.privacy;
	const { lang } = useLang();

	const [descEn, setDescEn] = useState('');
	const [descRu, setDescRu] = useState('');
	const [descHy, setDescHy] = useState('');
	const [id, setId] = useState('');

	const modelSendToServer = {
		descEn,
		descRu,
		descHy,
	};

	useEffect(() => {
		(async () => {
			if (id !== 'add') {
				let currentItem = await API.get(route);
				setId(currentItem[0]?._id);
				setDescEn(currentItem[0]?.descEn);
				setDescRu(currentItem[0]?.descRu);
				setDescHy(currentItem[0]?.descHy);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: descHy,
						setter: setDescHy,
						type: 'text',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'hy',
						fullWidth: true,
					},
					{
						value: descRu,
						setter: setDescRu,
						type: 'text',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'ru',
						fullWidth: true,
					},
					{
						value: descEn,
						setter: setDescEn,
						type: 'text',
						usageType: 'textarea',
						label: config.translate.desc[lang],
						placeholder: config.translate.desc[lang],
						lang: 'en',
						fullWidth: true,
					},
				]}
			/>
		);
}
