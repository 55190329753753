//   const pageTitle = 'Ծառայություն';
import React, { useState, useEffect } from "react";
import SinglePage from "../SinglePage";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useParams } from "react-router-dom";
import { useLang } from "../../helpers/language";
import { useUser } from "../../helpers/userContext";

export default function Service() {
  let id = useParams().id;
  const [isBusy, setBusy] = useState(true);
  const route = config.api.services;
  const { user } = useUser();
  const { lang } = useLang();
  const [image, setImage] = useState("");
  const [alt, setAlt] = useState("");

  const [nameEn, setnameEn] = useState("");
  const [nameRu, setnameRu] = useState("");
  const [nameHy, setnameHy] = useState("");
  const [descEn, setdescEn] = useState("");
  const [descRu, setdescRu] = useState("");
  const [descHy, setdescHy] = useState("");

  const [category_id, setcategory_id] = useState("");
  const [categories, setcategories] = useState("");

  let modelSendToServer = {};

  if (category_id) {
    modelSendToServer = {
      nameEn,
      nameRu,
      nameHy,
      descEn,
      descRu,
      descHy,
      category_id,
    };
  } else {
    modelSendToServer = {
      nameEn,
      nameRu,
      nameHy,
      descEn,
      descRu,
      descHy,
    };
  }

  useEffect(() => {
    (async () => {
      let rawCategory = await API.get(config.api.category);
      const categories = rawCategory;
      setcategories(categories);

      if (id !== "add") {
        let currentItem = await API.get(route, { _id: id }, null, true);
        setnameEn(currentItem[0]?.nameEn);
        setnameRu(currentItem[0]?.nameRu);
        setnameHy(currentItem[0]?.nameHy);
        setdescEn(currentItem[0]?.descEn);
        setdescRu(currentItem[0]?.descRu);
        setdescHy(currentItem[0]?.descHy);
        setcategory_id(currentItem[0]?.category_id);

        let images = currentItem[0]?.uploads?.filter(
          (item) => item.destiny === "image"
        );
        setImage(images && images?.[0]);
        setAlt(images?.[0]?.alt);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: nameHy,
            setter: setnameHy,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "hy",
            fullWidth: true,
          },
          {
            value: nameRu,
            setter: setnameRu,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "ru",
            fullWidth: true,
          },
          {
            value: nameEn,
            setter: setnameEn,
            type: "text",
            usageType: "textfield",
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: "en",
            fullWidth: true,
          },
          {
            value: category_id,
            field: "titleHy",
            setter: setcategory_id,
            optionListValue: categories,
            type: "optionlist",
            usageType: "optionlist",
            label: "կատեգորիան",
          },
          {
            value: descHy,
            setter: setdescHy,
            type: "text",
            usageType: "textarea",
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            lang: "hy",
            fullWidth: true,
          },
          {
            value: descRu,
            setter: setdescRu,
            type: "text",
            usageType: "textarea",
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            lang: "ru",
            fullWidth: true,
          },
          {
            value: descEn,
            setter: setdescEn,
            type: "text",
            usageType: "textarea",
            label: config.translate.desc[lang],
            placeholder: config.translate.desc[lang],
            lang: "en",
            fullWidth: true,
          },
        ]}
        filesComponent={[
          {
            destiny: "image",
            file: image,
            alt: alt,
            setAlt: setAlt,
            filesUploadLimit: 1,
            adminMode: user.role !== "editor",
            acceptedFiles: ".jpg, .jpeg, .png",
          },
        ]}
      />
    );
}
