import React, { useState, useEffect } from 'react';
import config from '../../config';
import Dropzone, { useDropzone } from 'react-dropzone';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loading from './loading';
import * as API from '../../helpers/api';
import { useHistory } from 'react-router-dom';

export function SliderComponent({
	id,
	files,
	setAlt,
	route,
	modelSendToServer,
	lang,
	destiny,
	thumbSizeWidth,
	thumbSizeHeight,
	responsiveImages,
	setBusy,
	dropzoneText,
	buttonOpenText,
	buttonRemoveText,
	slider,
	acceptedFiles,
	fileRejections,
	getRootProps,
	getInputProps,
	alt,
	filesUploadLimit,
	buttonAddText,
	buttonCloseText,
	setLocalProgress,
}) {
	const [openDialog, setDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const history = useHistory();

	var disabled = false;
	if (files && files[0] !== undefined) {
		disabled = true;
	}

	const handleClose = () => {
		setDialog(false);
	};

	const handleSave = async (files, lang, destiny, setProgress) => {
		setLoading(true);
		setDialog(false);
		if (id === 'add') {
			const rowData = await API.post(route, modelSendToServer);
			let id = rowData[0]._id;
			await history.push(`${id}`);
			let result = await API.upload(
				id,
				files,
				lang,
				destiny,
				thumbSizeWidth,
				thumbSizeHeight,
				responsiveImages,
				alt,
				setProgress
			);
			if (result) {
				setLoading(false);
			}
		} else {
			try {
				await API.update(route, id, modelSendToServer);
				try {
					let result = await API.upload(
						id,
						files,
						lang,
						destiny,
						thumbSizeWidth,
						thumbSizeHeight,
						responsiveImages,
						alt,
						setProgress
					);
					if (result) {
						setLoading(false);
					}
				} catch (err) {
					console.log(err);
				}
			} catch (error) {
				console.log(error);
			}
		}
		setBusy(true);
	};

	const handleOpen = () => {
		setDialog(true);
	};

	async function removeFile(e) {
		setLoading(true);
		try {
			await API.remove(config.api.upload, e.currentTarget.id);
			setLoading(false);
			setBusy(true);
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		// setLoading(false);
	}, [loading]);

	if (loading) return <Loading progress={progress} />;
	else
		return (
			<>
				<div className='col-md-12'>
					<div className='card card-primary'>
						<div className='card-header'>
							<h3 className='card-title'>Slider files</h3>
						</div>
						<div className='card-body d-block'>
							{disabled ? (
								<Carousel>
									{files.map((file, index) => {
										return (
											<div key={index}>
												<button
													onClick={removeFile}
													type='button'
													id={file._id}
													className='btn btn-danger position-absolute'
													style={{ top: 0, left: 0 }}
												>
													<i className='fas fa-trash'></i>
													<span
														style={{
															marginLeft: 10,
														}}
													>
														{buttonRemoveText}
													</span>
												</button>
												<img
													src={`${config.api.API_URL}/${file.path}`}
													alt=''
													height={'100%'}
												/>
											</div>
										);
									})}
								</Carousel>
							) : (
								<Dropzone
									accept={acceptedFiles}
									onDrop={(acceptedFiles) =>
										handleSave(
											acceptedFiles,
											lang,
											destiny,
											setProgress
										)
									}
								>
									{({ getRootProps, getInputProps }) => (
										<section className='dropzone'>
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												<p>{dropzoneText}</p>
											</div>
										</section>
									)}
								</Dropzone>
							)}
						</div>
						<div className='card-footer'>
							<button
								onClick={handleOpen}
								type='button'
								className='btn btn-primary'
							>
								<i className='fas fa-upload'></i>
								<span style={{ marginLeft: 10 }}>
									{buttonAddText}
								</span>
							</button>
						</div>
					</div>
				</div>
				<div
					className={openDialog ? 'modal fade show' : 'modal fade'}
					id='modal-xl'
					style={{
						display: openDialog ? 'block' : 'none',
						paddingRight: 16,
					}}
					aria-modal='true'
				>
					<div className='modal-dialog modal-xl'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h4 className='modal-title'>
									Ավելացնել ֆայլեր
								</h4>
								<button
									type='button'
									onClick={handleClose}
									className='close'
									data-dismiss='modal'
									aria-label='Close'
								>
									<span aria-hidden='true'>×</span>
								</button>
							</div>
							<div className='modal-body'>
								<Dropzone
									accept={acceptedFiles}
									onDrop={(acceptedFiles) =>
										handleSave(acceptedFiles)
									}
								>
									{({ getRootProps, getInputProps }) => (
										<section className='dropzone'>
											<div {...getRootProps()}>
												<input {...getInputProps()} />
												<p>{dropzoneText}</p>
											</div>
										</section>
									)}
								</Dropzone>
							</div>
							<div className='modal-footer justify-content-between'>
								<button
									type='button'
									onClick={handleClose}
									className='btn btn-default'
									data-dismiss='modal'
								>
									<i className='fas fa-window-close'></i>
									<span style={{ marginLeft: 10 }}>
										{buttonCloseText}
									</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
}
