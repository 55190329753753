//   const pageTitle = 'Հաստիք';
import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import * as API from '../../helpers/api';
import config from '../../config';
import Loading from '../modules/loading';
import { useParams } from 'react-router-dom';
import { useLang } from '../../helpers/language';

export default function Posiotion() {
  let id = useParams().id;
  const [isBusy, setBusy] = useState(true);
  const route = config.api.positions;
  const { lang } = useLang();

  const [nameEn, setNameEn] = useState('');
  const [nameRu, setNameRu] = useState('');
  const [nameHy, setNameHy] = useState('');
  const [subNameEn, setSubNameEn] = useState('');
  const [subNameRu, setSubNameRu] = useState('');
  const [subNameHy, setSubNameHy] = useState('');

  const modelSendToServer = {
    nameEn,
    nameRu,
    nameHy,
    subNameEn,
    subNameRu,
    subNameHy,
  };

  useEffect(() => {
    (async () => {
      if (id !== 'add') {
		let currentItem = await API.get(route);
        setNameEn(currentItem[0]?.nameEn);
        setNameRu(currentItem[0]?.nameRu);
        setNameHy(currentItem[0]?.nameHy);
        setSubNameEn(currentItem[0]?.subNameEn);
        setSubNameRu(currentItem[0]?.subNameRu);
        setSubNameHy(currentItem[0]?.subNameHy);
      }
      setBusy(false);
    })();
    // eslint-disable-next-line
  }, [isBusy]);

  if (isBusy) return <Loading />;
  else
    return (
      <SinglePage
        isBusy={isBusy}
        setBusy={setBusy}
        route={route}
        id={id}
        modelSendToServer={modelSendToServer}
        inputs={[
          {
            value: nameHy,
            setter: setNameHy,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'hy',
            fullWidth: true,
          },
          {
            value: nameRu,
            setter: setNameRu,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'ru',
            fullWidth: true,
          },
          {
            value: nameEn,
            setter: setNameEn,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.name[lang],
            placeholder: config.translate.name[lang],
            lang: 'en',
            fullWidth: true,
          },
          {
            value: subNameHy,
            setter: setNameHy,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.subName[lang],
            placeholder: config.translate.subName[lang],
            lang: 'hy',
            fullWidth: true,
          },
          {
            value: subNameRu,
            setter: setNameRu,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.subName[lang],
            placeholder: config.translate.subName[lang],
            lang: 'ru',
            fullWidth: true,
          },
          {
            value: subNameEn,
            setter: setNameEn,
            type: 'text',
            usageType: 'textfield',
            label: config.translate.subName[lang],
            placeholder: config.translate.subName[lang],
            lang: 'en',
            fullWidth: true,
          },
        ]}
      />
    );
}
