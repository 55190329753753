import React, { useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import * as API from "../helpers/api";
import { useLang } from "../helpers/language";
import config from "../config";

//! import modules
import Input from "./modules/input";
import { FilesComponent } from "./modules/filesComponent";
import { SliderComponent } from "./modules/sliderComponent";
import { TableComponent } from "./modules/tableComponent";
import { ToDoComponent } from "./modules/toDoComponent";
import { CartComponent } from "./modules/cartComponent";
import { VideoComponent } from "./modules/videoComponent";
import "date-fns";

import { useUser } from "../helpers/userContext";

export default function SinglePage(props) {
  const {
    inputs,
    simplePage,
    filesComponent,
    tableComponent,
    sliderComponent,
    toDoComponent,
    videoComponent,
    cartComponent,
    route,
    id,
    Status,
    History,
    modelSendToServer,
    setBusy,
    disabledButtons,
    userRoles = ["admin"],
  } = props;
  const location = useLocation();

  let history = useHistory();
  let editMode;
  let editedPageTitle = "";
  const { lang, setLang } = useLang();
  const [isValid, setValid] = useState(false);

  const { user } = useUser();

  const interactivePageTitle = () => {
    let result = "";
    Object.values(config.menu.primary).forEach((item) => {
      if (item.route) {
        if (location.pathname.indexOf(item.route) > 0) {
          result = item.name[lang];
        }
      } else {
        item.collapse.forEach((innerItem) => {
          if (location.pathname.indexOf(innerItem.route) > 0) {
            result = innerItem.name[lang];
          }
        });
      }
    });
    return result;
  };

  const params = new URLSearchParams(document.location.search).get(
    "temprorary"
  );

  if (useParams().id === "add") {
    editMode = false;
    editedPageTitle = `${
      config.translate.addNew[lang]
    } ${interactivePageTitle()}`;
  } else {
    editMode = true;
    editedPageTitle = `${
      config.translate.edit[lang]
    } ${interactivePageTitle()}`;
  }

  const createItem = async (action, modelSendToServer) => {
    setBusy(true);
    try {
      await API.post(route, modelSendToServer);
    } catch (error) {
      console.log(error);
    }
    if (action) action();
  };
  const updateItem = async (action, modelSendToServer) => {
    if (modelSendToServer.temprorary) {
      modelSendToServer.temprorary = false;
    }
    setBusy(true);
    await API.update(route, id, modelSendToServer);
    if (action) action();
  };
  const removeItem = async (action) => {
    setBusy(true);
    await API.remove(route, id);
    if (action?.prototype?.name === "Function") {
      action();
    }
  };

  // ! MAIN BUTTONS -------SAVE------REMOOVE------CANCEL
  const saveItem = async () => {
    let url = window.location.pathname;
    let intelligentBack = url.split("/");
    intelligentBack.pop();
    intelligentBack.shift();
    intelligentBack = intelligentBack.join();
    if (editMode) {
      if (History?.length > 0) {
        modelSendToServer.history = [
          ...History,
          {
            date: new Date(),
            user: user ? user : "No user selected",
            status: modelSendToServer?.status ? Status : "No status selected",
          },
        ];
      }
      updateItem(history.push(`/${intelligentBack}/`), modelSendToServer);
    } else {
      if (History?.length > 0) {
        modelSendToServer.history = [
          {
            date: new Date(),
            user: user.firstName?.hy
              ? user.firstName?.hy
              : user.firstName?.en
              ? user.firstName?.en
              : user.firstName?.ru
              ? user.firstName?.ru
              : "undefined",
            status: modelSendToServer?.status ? Status : "No status selected",
          },
        ];
      }
      createItem(history.push(`/${intelligentBack}/`), modelSendToServer);
    }
  };
  const cancelBack = () => {
    history.goBack();
  };
  const removeThis = () => {
    removeItem(history.goBack());
  };

  const [activator1, setactivator1] = useState(lang === "en" ? true : false);
  const [activator2, setactivator2] = useState(lang === "ru" ? true : false);
  const [activator3, setactivator3] = useState(lang === "hy" ? true : false);

  const tabs = [
    {
      name: "Հայ",
      activator: activator3,
      setter: setactivator3,
      lang: "hy",
    },
    {
      name: "Рус",
      activator: activator2,
      setter: setactivator2,
      lang: "ru",
    },
    {
      name: "Eng",
      activator: activator1,
      setter: setactivator1,
      lang: "en",
    },
  ];

  function toggleTab(e) {
    tabs.forEach((tab) => {
      tab.setter(false);
      if (e?.target?.id === tab?.lang) {
        tab.setter(true);
        setLang(tab.lang);
      }
    });
  }

  return (
    <>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12" align="center">
              <h1>{editedPageTitle}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header d-flex p-0">
                  <h3 className="card-title p-2">
                    {config.translate.mainInformation[lang]}
                  </h3>
                  <ul className="nav nav-pills ml-auto">
                    {tabs.map((tab, index) => (
                      <li className="nav-item" key={index}>
                        <button
                          onClick={toggleTab}
                          id={tab.lang}
                          className={`nav-link ${
                            tab.activator ? "active" : ""
                          }`}
                        >
                          {tab.name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div role="form">
                  <div className="card-body">
                    {inputs
                      ? inputs.map((input, index) => {
                          if (input) {
                            if (input.lang) {
                              if (input.lang === lang) {
                                return (
                                  <Input
                                    key={index}
                                    id={index}
                                    setValid={setValid}
                                    label={input.label}
                                    field={input.field}
                                    placeholder={input.placeholder}
                                    price={input.price}
                                    pricesetter={input.pricesetter}
                                    timeInterval={input.timeInterval}
                                    settimeInterval={input.settimeInterval}
                                    value={input.value}
                                    fullWidth={input.fullWidth}
                                    setter={input.setter}
                                    type={input.type}
                                    min={input.min}
                                    lang={input.lang}
                                    required={input.required}
                                    usageType={input.usageType}
                                    optionListValue={input.optionListValue}
                                    radioValue={input.radioValue}
                                    disabled={input.disabled}
                                    selectSomething={input.selectSomething}
                                    setValidator={input.setValidator}
                                    validator={input.validator}
                                    trim={input.trim}
                                    phoneCodeState={input.phoneCodeState}
                                    setPhoneCodeState={input.setPhoneCodeState}
                                  />
                                );
                              }
                            } else {
                              return (
                                <Input
                                  key={index}
                                  id={index}
                                  setValid={setValid}
                                  label={input.label}
                                  field={input.field}
                                  placeholder={input.placeholder}
                                  price={input.price}
                                  pricesetter={input.pricesetter}
                                  timeInterval={input.timeInterval}
                                  settimeInterval={input.settimeInterval}
                                  value={input.value}
                                  setter={input.setter}
                                  type={input.type}
                                  min={input.min}
                                  radioValue={input.radioValue}
                                  fullWidth={input.fullWidth}
                                  required={input.required}
                                  usageType={input.usageType}
                                  optionListValue={input.optionListValue}
                                  disabled={input.disabled}
                                  setValidator={input.setValidator}
                                  validator={input.validator}
                                  trim={input.trim}
                                  phoneCodeState={input.phoneCodeState}
                                  setPhoneCodeState={input.setPhoneCodeState}
                                />
                              );
                            }
                          }
                          return null;
                        })
                      : null}
                  </div>
                  <div className="">
                    {useParams().id !== "add" &&
                      sliderComponent &&
                      sliderComponent.map((component, index) => {
                        return (
                          <SliderComponent
                            key={index}
                            id={id}
                            index={index}
                            name={component.name}
                            destiny={component.destiny}
                            files={component.files}
                            filesUploadLimit={component.filesUploadLimit}
                            acceptedFiles={component.acceptedFiles}
                            lang={component.lang}
                            buttonAddText={component.buttonAddText}
                            buttonSaveText={component.buttonSaveText}
                            buttonCloseText={component.buttonCloseText}
                            buttonRemoveText={component.buttonRemoveText}
                            dropzoneText={component.dropzoneText}
                            setBusy={setBusy}
                            modelSendToServer={modelSendToServer}
                            route={route}
                            adminMode={component.adminMode}
                            thumbSizeWidth={component.thumbSizeWidth}
                            thumbSizeHeight={component.thumbSizeHeight}
                            responsiveImages={component.responsiveImages}
                          />
                        );
                      })}
                  </div>

                  <div className="">
                    {toDoComponent && (
                      <div className="card-primary">
                        <div className="card-body todo-component">
                          {toDoComponent.map((component, index) => {
                            return (
                              <ToDoComponent
                                key={index}
                                title={component.title}
                                data={component.data}
                                columns={component.columns}
                                setter={component.setter}
                                value={component.value}
                                pagination={component.pagination}
                                width={component.width}
                                customStyles={component.customStyles}
                                limit={component.limit}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="">
                    {useParams().id !== "add" &&
                      cartComponent &&
                      cartComponent.map((component, index) => {
                        return (
                          <CartComponent
                            key={index}
                            title={component.title}
                            data={component.data}
                            columns={component.columns}
                            setter={component.setter}
                            value={component.value}
                            pagination={component.pagination}
                            width={component.width}
                            customStyles={component.customStyles}
                          />
                        );
                      })}
                  </div>
                  <div className="">
                    {useParams().id !== "add" &&
                      tableComponent &&
                      tableComponent.map((component, index) => {
                        return (
                          <TableComponent
                            key={index}
                            title={component.title}
                            data={component.data}
                            columns={component.columns}
                            setter={component.setter}
                            conditionalRowStyles={
                              component.conditionalRowStyles
                            }
                          />
                        );
                      })}
                  </div>
                  {disabledButtons ? null : (
                    <div className="card-footer">
                      <div className="btn-group">
                        {id === "add" ? (
                          <>
                            <button
                              className="btn btn-primary"
                              disabled={isValid}
                              onClick={saveItem}
                            >
                              <i className="fas fa-paper-plane"></i>
                              <span
                                style={{
                                  marginLeft: 10,
                                }}
                              >
                                {config.translate.save["hy"]}
                              </span>
                            </button>
                            <button
                              disabled={isValid}
                              className="btn btn-warning"
                              onClick={cancelBack}
                            >
                              <i className="fas fa-window-close"></i>
                              <span
                                style={{
                                  marginLeft: 10,
                                }}
                              >
                                {config.translate.cancel["hy"]}
                              </span>
                            </button>
                            )
                          </>
                        ) : userRoles.includes(user?.role) ? (
                          <>
                            <button
                              disabled={isValid}
                              className="btn btn-primary"
                              onClick={saveItem}
                            >
                              <i className="fas fa-save"></i>
                              <span
                                style={{
                                  marginLeft: 10,
                                }}
                              >
                                {config.translate.save["hy"]}
                              </span>
                            </button>
                            {params ? (
                              <button
                                disabled={isValid}
                                className="btn btn-warning"
                                onClick={removeThis}
                              >
                                <i className="fas fa-window-close"></i>
                                <span
                                  style={{
                                    marginLeft: 10,
                                  }}
                                >
                                  {config.translate.cancel["hy"]}
                                </span>
                              </button>
                            ) : (
                              <button
                                disabled={isValid}
                                className="btn btn-warning"
                                onClick={cancelBack}
                              >
                                <i className="fas fa-window-close"></i>
                                <span
                                  style={{
                                    marginLeft: 10,
                                  }}
                                >
                                  {config.translate.cancel["hy"]}
                                </span>
                              </button>
                            )}
                          </>
                        ) : null}
                        {editMode
                          ? simplePage
                            ? null
                            : userRoles.includes(user?.role)
                            ? !params && (
                                <button
                                  disabled={isValid}
                                  className="btn btn-danger"
                                  onClick={removeThis}
                                >
                                  <i className="fas fa-trash"></i>
                                  <span
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  >
                                    {config.translate.remove["hy"]}
                                  </span>
                                </button>
                              )
                            : null
                          : null}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {useParams().id !== "add" &&
              videoComponent &&
              videoComponent.map((component, index) => {
                return (
                  <VideoComponent
                    key={index}
                    id={id}
                    file={component.file}
                    filesUploadLimit={component.filesUploadLimit}
                    acceptedFiles={component.acceptedFiles}
                    lang={component.lang}
                    alt={component.alt}
                    setAlt={component.setAlt}
                    setBusy={setBusy}
                    modelSendToServer={modelSendToServer}
                    route={route}
                    destiny={component.destiny}
                    buttonAddText={component.buttonAddText}
                    buttonOpenText={component.buttonOpenText}
                    buttonRemoveText={component.buttonRemoveText}
                    adminMode={component.adminMode}
                    dropzoneText={component.dropzoneText}
                  />
                );
              })}

            {filesComponent &&
              filesComponent.map((component, index) => {
                return (
                  <FilesComponent
                    key={index}
                    id={id}
                    destiny={component.destiny}
                    file={component.file}
                    filesUploadLimit={component.filesUploadLimit}
                    acceptedFiles={component.acceptedFiles}
                    lang={component.lang}
                    alt={component.alt}
                    setAlt={component.setAlt}
                    buttonAddText={component.buttonAddText}
                    buttonOpenText={component.buttonOpenText}
                    buttonRemoveText={component.buttonRemoveText}
                    dropzoneText={component.dropzoneText}
                    setBusy={setBusy}
                    modelSendToServer={modelSendToServer}
                    route={route}
                    adminMode={component.adminMode}
                    thumbSizeWidth={component.thumbSizeWidth}
                    thumbSizeHeight={component.thumbSizeHeight}
                    responsiveImages={component.responsiveImages}
                  />
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
}
