import React from "react";

const Pagination = ({ dataPerPage, totalCount, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalCount / dataPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <ul
      className={"pagination pagination-sm flex-center flex-wrap"}
      style={{ width: "100%" }}
    >
      {pageNumbers.map((number) => {
        return (
          <li
            key={number}
            className={
              currentPage === number
                ? `${"active"} ${"page-item"}`
                : "page-item"
            }
          >
            <span onClick={() => paginate(number)} className={"page-link"}>
              {number}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default Pagination;
